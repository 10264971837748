import React from "react";

import ChatContainer from "@/components/chat/ChatContainer";
import { isBrowser } from "@/utils/env";

const VideoSessionPage = () => {
  if (!isBrowser()) {
    return null;
  }

  return <ChatContainer />;
};

export default VideoSessionPage;
